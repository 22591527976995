import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  lockIcon,
  unLockIcon,
  propertyImg,
  tenantImg,
  shareIcon,
  goldMedalIcon,
  silverMedalIcon,
  bronzeMedalIcon,
  unfilledStarIcon,
  filledStarIcon,
  buildingIconWhite,
} from "../../../utils/Images.js";
import { addDefaultSrc, sizeFormat } from "../../../utils/Helper";
import { propertyType } from "../../../utils/Switch";
import BasicMenu from "../../../components/dialog/Menu";
import CompService from "../../../services/component/Comp";
import "./my-comps.css";

const AWS_S3 = process.env.REACT_APP_AWS_S3URL;

export default function MyComps({
  search,
  myComps,
  hasMore,
  page,
  type,
  category,
  getNextComps,
  handleRefresh,
  handleFilterPointer,
  baseRent,
  property,
  size,
  brokers,
  commencement,
}) {
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const userId = useSelector((state) => state?.auth?.user?._id);
  const open = Boolean(anchorEl);

  const levelImage = (value) => {
    let level;
    if (value) {
      switch (value) {
        case 1:
          level = goldMedalIcon;
          break;
        case 2:
          level = silverMedalIcon;
          break;
        default:
          level = bronzeMedalIcon;
      }
      return level;
    }
  };

  const handleDetail = (id, event) => {
    navigate(`../comp-detail/${id}`);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setId(id);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };

  const handleBroker = () => {
    navigate(`../comp-detail/${id}/share-broker?list=true`);
    handleClose();
  };

  const handleNonBroker = () => {
    navigate(`../comp-detail/${id}/share-non-broker?list=true`);
    handleClose();
  };

  const handleFavourite = (event, id) => {
    event.stopPropagation();

    if (id) {
      CompService.addFavoriteComp({ compId: id })
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message);
            handleRefresh();
          }
        })
        .catch((error) => {
          console.log("er", error);
          toast.error(error.response.data.message);
        });
    }
  };

  const handleMessageDisplay = () => {
    if (
      baseRent.length > 0 ||
      property.length > 0 ||
      size.length > 0 ||
      brokers.length > 0 ||
      commencement.length > 0 ||
      search
    ) {
      return true;
    }
    return false;
  };

  return (
    <div
      className={`dashboard-body  mt-10 ${
        myComps && myComps?.length > 0 ? "infinite-scroller" : ""
      }  `}
      id='scrollableDiv'
    >
      <InfiniteScroll
        dataLength={myComps.length}
        next={myComps?.length > 0 && getNextComps}
        hasMore={hasMore}
        loader={
          <h1 className='subtext center' style={{ padding: "20px" }}>
            {" "}
            {myComps && myComps?.length > 0
              ? "Scroll to load More ..."
              : "Loading ..."}
          </h1>
        }
        endMessage={
          <h1 className='subtext center' style={{ padding: "100px 20px" }}>
            {myComps && myComps?.length > 0
              ? "No More Items Found"
              : handleMessageDisplay()
              ? "No Result Found"
              : `Click + to add a comp`}
          </h1>
        }
        scrollableTarget='scrollableDiv'
      >
        <Grid container spacing={2}>
          {myComps &&
            myComps.length > 0 &&
            myComps.map((comp) => (
              <Grid item xs={12} sm={12} md={6} key={comp._id}>
                <div
                  className='comp-card'
                  onClick={() => handleDetail(comp._id)}
                >
                  <div className='comp-header'>
                    <img
                      alt='prop-img'
                      className='prop-img'
                      onError={(e) => addDefaultSrc(e, propertyImg)}
                      src={
                        !comp?.buildingPhotoUrl
                          ? propertyImg
                          : AWS_S3 + comp?.buildingPhotoUrl
                      }
                    />
                    <div className='justify-end icons-cover'>
                      <img
                        alt='fav-img'
                        className='pointer mr-30'
                        src={
                          comp.hasOwnProperty("Favorites")
                            ? filledStarIcon
                            : unfilledStarIcon
                        }
                        onClick={(e) => handleFavourite(e, comp._id)}
                      />
                      <img
                        alt='share-img'
                        className='pointer'
                        src={shareIcon}
                        onClick={(e) => handleClick(e, comp._id)}
                      />
                    </div>
                    <div className='image-bottom-content'>
                      <img
                        alt='lock-img'
                        className='mb-10'
                        src={comp?.isLocked ? lockIcon : unLockIcon}
                      />
                      <div className='space-between'>
                        <div className='brokers-list justify-start'>
                          {comp?.brokers?.length > 0 &&
                            comp?.brokers
                              .filter((x) => x.isEditor && x.type === 2)
                              .map((seller) => (
                                <div
                                  className='broker justify-start'
                                  key={seller._id}
                                >
                                  <img
                                    onError={(e) => addDefaultSrc(e, tenantImg)}
                                    src={
                                      !seller?.brokerDetail?.profilePic
                                        ? tenantImg
                                        : AWS_S3 +
                                          seller?.brokerDetail?.profilePic
                                    }
                                    alt='broker-img'
                                  />
                                  <h2>
                                    {seller?.brokerId === userId
                                      ? "You"
                                      : seller?.brokerDetail?.name}
                                  </h2>
                                </div>
                              ))}
                          {comp?.brokers?.length > 0 &&
                            comp?.brokers
                              .filter((x) => x.isEditor && x.type === 1)
                              .map((buyer) => (
                                <div
                                  className='broker justify-start'
                                  key={buyer._id}
                                >
                                  <img
                                    onError={(e) => addDefaultSrc(e, tenantImg)}
                                    src={
                                      !buyer?.brokerDetail?.profilePic
                                        ? tenantImg
                                        : AWS_S3 +
                                          buyer?.brokerDetail?.profilePic
                                    }
                                    alt='broker-img'
                                  />
                                  <h2>
                                    {buyer?.brokerId === userId
                                      ? "You"
                                      : buyer?.brokerDetail?.name}
                                  </h2>
                                </div>
                              ))}
                        </div>
                        <img
                          src={
                            comp?.level ? levelImage(comp.level) : goldMedalIcon
                          }
                          alt='badge-img'
                          className='badge-img'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='comp-body bg-white'>
                    <h2 className='mb-15'>
                      <span className='subtext'>Address: </span>
                      <strong>{comp?.fullAddress || "N/A"}</strong>
                    </h2>

                    {comp?.tenantCompanyName && (
                      <h2 className='mb-15'>
                        <span className='subtext'>Tenant Company Name: </span>
                        {comp?.tenantCompanyName || "N/A"}
                      </h2>
                    )}

                    {comp?.buyerName && (
                      <h2 className='mb-15'>
                        <span className='subtext'>Buyer Name: </span>
                        {comp?.buyerName || "N/A"}
                      </h2>
                    )}

                    <h2 className='mb-15'>
                      <span className='subtext'>Suite: </span>
                      {comp?.suite || "N/A"}
                    </h2>

                    <h2 className='mb-15'>
                      <span className='subtext'>Square Feet: </span>
                      {comp?.size ? sizeFormat(comp.size) : "N/A"}
                    </h2>
                    <button
                      className='btn btn-primary btn-small justify-start'
                      style={{
                        padding: "7px 15px",
                        borderRadius: "5px",
                        fontWeight: "500",
                        fontSize: "15px",
                        letterSpacing: ".5px",
                      }}
                    >
                      <img
                        alt='building-img'
                        src={buildingIconWhite}
                        style={{ width: "16px", marginRight: "8px" }}
                      />

                      {comp?.propertyType
                        ? propertyType(comp.propertyType)
                        : ""}
                    </button>
                  </div>
                </div>
              </Grid>
            ))}
        </Grid>
      </InfiniteScroll>
      <BasicMenu
        handleClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        handleBroker={handleBroker}
        handleNonBroker={handleNonBroker}
      />
    </div>
  );
}
