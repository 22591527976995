import React from "react";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

import {
  convertNumToDecimal,
  currencyFormat,
  sizeFormat,
} from "../../../utils/Helper";
import { propertyType } from "../../../utils/Switch";

export default function BasicInfo({ comp }) {
  const unit = useSelector((state) => state?.auth?.user?.rentUnit?.toString());

  const calculateValue = (salePrice, sizeSale) => {
    let value = salePrice / sizeSale;
    //value = value * 100;
    return convertNumToDecimal(value, 2);
  };

  const leaseType = (value) => {
    let type = "";
    if (value) {
      switch (value) {
        case 1:
          type = "DIRECT";
          break;

        case 2:
          type = "SUBLEASE";
          break;
        case 3:
          type = "RENEW";
          break;
        case 4:
          type = "EXPANSION";
          break;

        default:
          type = "DOWN SIZE";
      }
      return type;
    }
  };

  const rentType = (value) => {
    let type = "";
    if (value) {
      switch (value) {
        case 1:
          type = "FS";
          break;
        case 2:
          type = "NNN";
          break;
        default:
          type = "MG";
      }
      return type;
    }
  };

  const unitType = (value) => {
    let type = "";
    if (value) {
      switch (value) {
        case 1:
          type = "MONTH";
          break;
        default:
          type = "YEAR";
      }
      return type;
    }
  };

  const tlsUnitType = (comp) => {
    if (comp?.TISUnit) {
      switch (comp?.TISUnit) {
        case 1:
          return `$ ${currencyFormat(comp?.TIS)} / SQFT`;
        case 2:
          return "Turn Key";
        default:
          return "$ 0 / SF";
      }
    }
  };

  const handleMonthYearValue = (value, type) => {
    const unitVal = Number(unit);
    if (unitVal === type) {
      return value;
    } else if (unitVal === 1) {
      return value * 12;
    } else if (unitVal === 2) {
      return (value / 12).toFixed(2);
    }
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 8 }}>
      <Grid item xs={12}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 8 }}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={3}>
              {comp.type === 1 && (
                <>
                  <Grid item xs={12}>
                    <h4 className="subtext mb-5 required">
                      Tenant Company Name
                    </h4>
                    <TextField
                      disabled
                      value={comp?.tenantCompanyName}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h4 className="subtext mb-5 required">Rent Type</h4>
                    <br />
                    <div className="badge">
                      {" "}
                      {comp?.rentType ? rentType(comp.rentType) : ""}{" "}
                    </div>
                  </Grid>
                </>
              )}

              {comp.type === 2 && (
                <>
                  <Grid item xs={12}>
                    <h4 className="subtext mb-5 required">Buyer Name</h4>
                    <TextField
                      disabled
                      value={comp?.buyerName}
                      variant="outlined"
                    />
                  </Grid>
                  {comp?.propertyType !== 6 && (
                    <Grid item xs={12}>
                      <h4 className="subtext mb-5 required">Class</h4>
                      <br />
                      <div className="badge">{comp?.class}</div>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h4 className="subtext mb-5 required">Property Type</h4>
                <br />
                <div className="badge">
                  {" "}
                  {comp?.propertyType ? propertyType(comp.propertyType) : ""}
                </div>
              </Grid>
              {comp.type === 1 && (
                <Grid item xs={12}>
                  <h4 className="subtext mb-5 required">Commencement</h4>
                  <TextField
                    disabled
                    value={
                      comp?.commencement
                        ? dayjs(comp?.commencement).format("MM/DD/YYYY")
                        : ""
                    }
                    variant="outlined"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {comp.type === 2 && (
        <>
          <Grid item xs={12} sm={12} md={6}>
            <div className="bg-light-blue">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <h4 className="subtext mb-5 required">Sale Price</h4>
                  <TextField
                    disabled
                    value={`$ ${currencyFormat(comp?.salePrice)}`}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h4 className="subtext mb-5 required">Size</h4>
                  <TextField
                    disabled
                    value={
                      comp?.size ? `${sizeFormat(comp?.size)} SQFT` : "N/A"
                    }
                    variant="outlined"
                  />
                  <div className="justify-end right mt-20">
                    <p className="subtext mr-20">Sale Price / SQFT</p>
                    <div className="flex-center sale-price-cover">
                      {comp?.salePrice && comp?.size
                        ? `$ ${calculateValue(comp?.salePrice, comp?.size)}`
                        : "0"}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Close of Escrow Date</h4>
            <TextField
              disabled
              value={
                comp?.escrowCloseDate
                  ? dayjs(comp?.escrowCloseDate).format("MM/DD/YYYY")
                  : ""
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 ">Cap Rate</h4>
            <TextField
              disabled
              value={comp?.capRate ? `${comp?.capRate} %` : "N/A"}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 ">NOI</h4>
            <TextField
              disabled
              value={comp?.NOI ? `$ ${currencyFormat(comp?.NOI)}` : "N/A"}
              variant="outlined"
            />
          </Grid>
        </>
      )}

      {comp.type === 1 && (
        <>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Term</h4>
            <TextField
              disabled
              value={`${comp?.term} ${
                comp?.term === 1 ? "MONTH" : "MONTH(s)"
              } `}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Size</h4>
            <TextField
              disabled
              value={comp?.size ? `${sizeFormat(comp?.size)} SQFT` : "N/A"}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Lease Expiration</h4>
            <TextField
              disabled
              value={
                comp?.leaseExpiration
                  ? dayjs(comp?.leaseExpiration).format("MM/DD/YYYY")
                  : ""
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Free Rent</h4>
            <TextField
              disabled
              value={`${comp?.freeRent} ${
                comp?.freeRent === 1 ? "MONTH" : "MONTH(s)"
              }`}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Base Rent</h4>
            <TextField
              disabled
              value={`$ ${handleMonthYearValue(
                currencyFormat(comp?.baseRent),
                comp.baseRentUnit
              )} / SQFT / ${unit === "2" ? "YEAR" : "MONTH"}`}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 ">Effective Rent</h4>
            <TextField
              disabled
              value={
                comp?.effectiveRent
                  ? `$ ${handleMonthYearValue(
                      currencyFormat(comp?.effectiveRent),
                      comp?.effectiveRentUnit
                    )} / SQFT / ${unit === "2" ? "YEAR" : "MONTH"}`
                  : "N/A"
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className={`subtext mb-5`}>Expenses</h4>
            <TextField
              disabled
              value={
                comp?.expenses
                  ? `$ ${handleMonthYearValue(
                      currencyFormat(comp?.expenses),
                      comp?.expensesUnit
                    )} / SQFT / ${unit === "2" ? "YEAR" : "MONTH"}`
                  : "N/A"
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Tls</h4>
            <TextField disabled value={tlsUnitType(comp)} variant="outlined" />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Escalations</h4>
            <TextField
              disabled
              value={
                comp?.escalationUnit === 3
                  ? `${currencyFormat(comp?.escalation)} %`
                  : `$ ${currencyFormat(comp?.escalation)} / SQFT / ${
                      comp?.escalationUnit ? unitType(comp.escalationUnit) : ""
                    }`
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5 required">Lease Type</h4>
            <br />
            <div className="badge">
              {" "}
              {comp?.leaseType ? leaseType(comp.leaseType) : ""}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
}
