import React from "react";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import FormControl from "@mui/material/FormControl";
import { Grid } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

export default function DetailTab({ comp }) {
  const userId = useSelector((state) => state?.auth?.user?._id);

  const traderName = (trade) => {
    if (userId === trade?.tradedBy) {
      return trade?.tradedWithDetails;
    }
    return trade?.tradedByDetails;
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={{ xs: 2, sm: 4, md: 8 }}>
      {comp.type === 1 && (
        <>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container spacing={3}>
              {comp?.class && (
                <Grid item xs={12}>
                  <FormControl>
                    <h4 className="subtext mb-5">Class</h4>
                    <div className="badge">{comp?.class || "N/A"}</div>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <h4 className="subtext mb-5">Signature Date</h4>
                <TextField
                  disabled
                  value={
                    comp?.signatureDate
                      ? dayjs(comp?.signatureDate).format("MM/DD/YYYY")
                      : "N/A"
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5">Parking</h4>
            <div className="bg-light-blue">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <h4 className="subtext mb-5">Stalls</h4>
                  <TextField
                    disabled
                    value={
                      comp?.parkingStalls
                        ? ` ${comp?.parkingStalls} / 1000 SQFT `
                        : "N/A"
                    }
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <h4 className="subtext mb-5">Parking Charges</h4>
                  <TextField
                    disabled
                    value={
                      comp?.parkingCharges
                        ? `$ ${comp?.parkingCharges} / SQFT / MONTH `
                        : "N/A"
                    }
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>

          {comp?.Trades?.length > 0 && (
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 className="subtext mb-5">Trade Details</h4>
                  <TextField
                    disabled
                    value={
                      comp?.Trades.length > 0
                        ? dayjs(comp?.Trades[0]?.updatedAt).format("MM/DD/YYYY")
                        : "N/A"
                    }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {comp?.Trades.length > 0 && (
                            <Link
                              to={`/comp/comp-detail/broker/${
                                traderName(comp?.Trades[0])?._id
                              }`}
                              style={{ fontWeight: 700 }}
                            >
                              {traderName(comp?.Trades[0])?.name}
                            </Link>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {comp.type === 2 && (
        <>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5">Year Built</h4>
            <TextField
              disabled
              value={comp?.yearBuilt || "N/A"}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5">Zoning</h4>
            <TextField
              disabled
              value={comp?.zoning || "N/A"}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5">Days in Escrow</h4>
            <TextField
              disabled
              value={
                `${comp?.escrowDays} ${
                  comp?.escrowDays >= 2 ? "Day(s)" : "Day"
                }` || "N/A"
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h4 className="subtext mb-5">Parking Ratio</h4>
            <TextField
              disabled
              value={
                comp?.parkingRatio ? `${comp?.parkingRatio} / 1000 SQFT` : "N/A"
              }
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <h4 className="subtext mb-5">Sale Comment</h4>
            <TextField
              disabled
              value={comp?.saleComment || "N/A"}
              variant="outlined"
              multiline
              minRows="3"
            />
          </Grid>

          {comp?.Trades?.length > 0 && (
            <Grid item xs={12} sm={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <h4 className="subtext mb-5">Trade Details</h4>
                  <TextField
                    disabled
                    value={
                      comp?.Trades.length > 0
                        ? dayjs(comp?.Trades[0]?.updatedAt).format("MM/DD/YYYY")
                        : "N/A"
                    }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {comp?.Trades.length > 0 && (
                            <Link
                              to={`/comp/comp-detail/broker/${
                                traderName(comp?.Trades[0])?._id
                              }`}
                              style={{ fontWeight: 700 }}
                            >
                              {traderName(comp?.Trades[0])?.name}
                            </Link>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
